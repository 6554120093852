@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* colors */
  --var-color-ffs-purple: #ab54a2;
  --var-color-ffs-purple-d: #2e296b;
  --var-color-ffs-navy: #0d5773;
  --var-color-ffs-cyan: #5cc9ea;
  --var-color-ffs-white: #ffffff;
  --var-color-ffs-grey: #e6e6e6;
  /* font families */
  /* font sizes */
  --black-font-size: 100px;
  --headline-font-size: 54px;
}

body {
  @apply bg-gray-ffs text-denim-ffs;
  font-family: "Quicksand", sans-serif;
  line-height: 23px;
}

.bg-footer {
  background: linear-gradient(
    63deg,
    rgba(9, 103, 132, 1),
    88%,
    rgba(171, 84, 162, 1) 97%
  );
}

h1,
h2 {
  @apply text-[36px] leading-[42px] lg:leading-[70px] lg:text-[56px] font-bold text-crimson-ffs;
}

h3 {
  @apply text-[30px] leading-[35px] font-bold;
}

h4 {
  @apply text-[24px] font-bold;
}

@layer utilities {
  .line-up {
    @apply relative;
  }
  .line-up::before {
    @apply absolute h-0.5 left-1/2 -translate-x-1/2 w-44 lg:w-[261px] rounded-full bg-crimson-ffs block mb-1;
    content: "";
  }
}

.with-transition {
  @apply transition-all duration-500 ease-in-out;
}

/* nav hover line */
.menu-item .menu-item__line {
  background-color: transparent;
}

.menu-item:hover .menu-item__line {
  @apply bg-poly-cyan;
}
.menu-item:hover .menu-item__link {
  @apply text-poly-cyan;
}

.selected-menu-item .menu-item__line {
  @apply bg-poly-cyan;
}
.selected-menu-item .menu-item__link {
  @apply text-poly-cyan;
}
/*  */

.app-slider::-webkit-scrollbar,
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.app-slider,
.no-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.app-slider .app-slider__band.move {
  animation: move-carousel linear infinite;
  -webkit-animation: move-carousel linear infinite;
}

.bg-arrow-slider {
  @apply bg-crimson-ffs h-fit top-1/2 -translate-y-1/2 aspect-square rounded-full
}

/* APPCARDS */x
@media (min-width: 1024px) {
  /* ANIMATION */
  .animated-card .animated-card__title,
  .animated-card .animated-card__text {
    @apply transition-all ease-out duration-500;
  }
  .animated-card .animated-card__title {
    @apply translate-y-[19rem];
  }
  .animated-card .animated-card__text {
    @apply opacity-0;
  }
  .animated-card:hover .animated-card__title {
    @apply translate-y-0;
  }
  .animated-card:hover .animated-card__text {
    @apply opacity-100;
  }
  /* ANIMATION END */
}
